/*=====================================================
15. shop-home-2
======================================================*/

/* 15.1 wpo-category-section */

.wpo-category-section {
    .wpo-category-wrap {
        .wpo-category-item {
            margin-bottom: 30px;

            .wpo-category-img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                background: #FFF;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
                padding: 15px;
                max-width: 375px;
                margin: 0 auto;

                .wpo-category-img-inner {
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        -webkit-transition: all .3s;
                        transition: all .3s;
                        -webkit-transform: scale(1);
                        transform: scale(1);

                    }
                }

                img {
                    border-radius: 50%;
                    width: 100%;
                }
            }

            &:hover {
                .wpo-category-img {
                    .wpo-category-img-inner {
                        img {
                            -webkit-transform: scale(1.2) rotate(5deg);
                            transform: scale(1.2) rotate(5deg);

                        }
                    }
                }
            }


            .wpo-category-text {
                text-align: center;
                margin-top: 30px;

                a {
                    text-align: center;
                    font-family: $heading-font;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $dark-gray;

                    @media(max-width:1399px) {
                        font-size: 25px;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }
}


/* 15.2 wpo-offer-section-s3 */

.wpo-offer-section-s3 {
    .wpo-offer-wrap {
        padding:120px 0;        
        padding-left: 120px;

        @media(max-width:1700px){
            padding-left: 30px;
        }
        @media(max-width:991px){
            padding:90px 15px;   
        }
        @media(max-width:767px){
            padding:80px 15px;   
        }
        
        .wpo-offer-items {
            max-width: 560px;
            margin: 0;
            border: 1px solid #FFF;
            background: linear-gradient(140deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.40) 100%);
            box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(10px);
            position: relative;

            .wpo-offer-item {
                border-color: $theme-primary-color;
            }
        }
    }
}