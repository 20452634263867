/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&family=Noto+Kufi+Arabic:wght@300;400;500;600;700&family=Sail&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Jost', sans-serif;
$heading-font: 'Sail', cursive;



// color
$dark-gray: #101010;
$body-color: #5C5C5C;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #657150;
$theme-primary-color-s2: #C8A898;
$theme-primary-color-s3: #A5AA9C;
$theme-primary-color-s4: #E5D1C7;
$body-bg-color: #fff;
$section-bg-color: #FCFDFA;
$section-bg-color-s2: #FAF2EE;
$section-bg-color-s3: #F1F3EE;
$section-bg-color-s4: #F5F5F5;
$text-color: #5C5C5C;
$text-light-color: #7b7b7b;
$heading-color: $dark-gray;
$heading-color2: #3F3F3F;
$border-color: #F3ECE9;
$border-color-s2: #e4ebf2;
